import { useEffect } from "react";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import SuccessMessage from "../SuccessMessage/SuccessMessage";

export const ServerMessage = ({ messageObj }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [messageObj])

  if (!messageObj) {
    return null;
  }

  return messageObj.type === "ERROR" ? (
    <ErrorMessage message={messageObj.message} />
  ) : (
    <SuccessMessage message={messageObj.message} />
  );
};
