import { API_URL } from "../constants";

export const getUsers = async (role = null, query = null) => {
  const requestUrl = `${API_URL}/users${
    role
      ? `?role=${role}${query ? `&query=${query}` : ""}`
      : query
      ? `?query=${query}`
      : ""
  }`;
  try {
    const response = await global.fetch(requestUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const getUser = async (userId) => {
  try {
    const response = await global.fetch(`${API_URL}/users/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const saveUser = async (password, token) => {
  try {
    const response = await global.fetch(`${API_URL}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({password, token}),
    });
    if (response.status === 409) {
      return {
        errorMessage: "Użytkownik o podanym adresie e-mail już istnieje.",
        statusCode: 409,
      };
    }

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const updateUser = async (userId, userDetails) => {
  try {
    const response = await global.fetch(`${API_URL}/users/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(userDetails),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const addUserDetails = async (userId, userDetails) => {
  try {
    const response = await global.fetch(`${API_URL}/users/${userId}/details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(userDetails),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const updateUserDetails = async (userId, userDetails) => {
  try {
    const response = await global.fetch(`${API_URL}/users/${userId}/details`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(userDetails),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const updateUserEducationalGoals = async (userId, educationalGoals) => {
  try {
    const response = await global.fetch(`${API_URL}/users/${userId}/educational-goals`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(educationalGoals),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const deleteUser = async (userId) => {
  try {
    await global.fetch(`${API_URL}/users/${userId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
};

export const restoreUser = async (userId) => {
  try {
    await global.fetch(`${API_URL}/users/${userId}/restore`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
  } catch (e) {
    console.log(e);
  }
};

export const muteUser = async (userId, muted) => {
  try {
    const response = await global.fetch(`${API_URL}/users/${userId}/mute`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify(muted),
    });

    return response.json();
  } catch (e) {
    console.log(e);
  }
};
