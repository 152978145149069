import { API_URL } from "../constants";

export const getInvitations = async () => {
  try {
    const response = await global.fetch(
      `${API_URL}/invitations`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      },
    );

    return response.json();
  } catch (e) {
    console.log(e);
  }
};

export const sendInvitation = async (invitation) => {
    try {
      const response = await global.fetch(
        `${API_URL}/invitations`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(invitation),
          credentials: "include",
        },
      );
  
      return response.json();
    } catch (e) {
      console.log(e);
    }
  };

  export const removeInvitation = async (invitationId) => {
    try {
        await global.fetch(
          `${API_URL}/invitations/${invitationId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          },
        );

      } catch (e) {
        console.log(e);
      }
    }