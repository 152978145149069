import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Badge,
    Box,
    TextField,
    FormControl,
    InputLabel,
  } from "@mui/material";
import { InvitationsColumnType } from "../../../models/TableModel";
import { ServerMessage } from "../../../components/ServerMessage/ServerMessage";
import { useContext, useEffect, useState } from "react";
import { getInvitations, removeInvitation, sendInvitation } from "../../../api/invitationsClient";
import emailValidator from "../../../validators/emailValidator";
import Button from "../../../components/Button/Button";
import ButtonMUI from "@mui/material/Button";
import { ModalContext } from "../../../contexts/ModalContext";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { UserRole } from "../../../constants";
import Select from "@mui/material/Select";
import getRoleNicename from "../../../helpers/getRoleNicename";

export const Invitations = () => {
    const { showModal } = useContext(ModalContext);
    const [serverMessage, setServerMessage] = useState(null);
    const [invitations, setInvitations] = useState([]);
    const [emailError, setEmailError] = useState(false);
    const [newInvitation, setNewInvitation] = useState({
        recipient: "",
        first_name: "",
        last_name: "",
        role: ""
    });

    const columns: readonly InvitationsColumnType[] = [
        { id: "recipient", label: "E-mail" },
        { id: "first_name", label: "Imię" },
        { id: "last_name", label: "Nazwisko" },
        { id: "role", label: "Rola" },
        { id: "expires_at", label: "Wygasa" },
        {
            id: "actions",
            label: "",
            align: "right",
        },
    ];

    const _fetchInvitations = async (query = null) => {
        try {
            const fetchedInvitations = await getInvitations();
            setInvitations(fetchedInvitations.invitations);
        } catch (err) {
            setServerMessage({
            type: "ERROR",
            message: "Nie udało się pobrać wysłanych zaproszeń.",
            });
        }
    };

    useEffect(() => {
        _fetchInvitations();
    }, []);

    const handleInputChange = (e) =>
        setNewInvitation({ ...newInvitation, [e.target.id]: e.target.value });

    const handleSend = async () => {
        try {
            const response = await sendInvitation(newInvitation);
            setInvitations((inv) =>
                [...inv, response]
            );

            setNewInvitation({
                recipient: "",
                first_name: "",
                last_name: "",
                role: "",
            });
            setServerMessage({
                type: "SUCCESS",
                message: "Zaproszenie zostało wysłane.",
            });
        } catch (err) {
            setServerMessage({
                type: "ERROR",
                message: "Nie udało się wysłać zaproszenia.",
            });
        }
    };

    const handleInvitationDelete = async (invitationId) => {
        try {
            await removeInvitation(invitationId);
            setInvitations((inv) =>
                inv.filter((invitation) => invitation.invitation_id !== invitationId)
            );
            setServerMessage({
                type: "SUCCESS",
                message: "Zaproszenie zostało usunięte.",
            })
        } catch (err) {
            setServerMessage({
                type: "ERROR",
                message: "Nie udało się usunąć zaproszenia. Spróbuj ponownie później.",
            });
        }
    }

    const showDeleteModal = (invitationId) => {
        showModal(ConfirmationModal, {
          action: () => handleInvitationDelete(invitationId),
          text: "Czy na pewno chcesz usunąć zaproszenie? Operacja jest nieodwracalna",
        });
      };

    const handleSelectChange = (e) => setNewInvitation({ ...newInvitation, role: e.target.value });

            
    return (
        <>
            <ServerMessage messageObj={serverMessage} />

            <h1>Wyślij zaproszenie</h1>

            <Box width={1 / 2}>
                <Box marginBottom="16px" width="100%">
                    <TextField
                        variant="outlined"
                        id="recipient"
                        label="E-mail"
                        fullWidth
                        error={emailError}
                        helperText={emailError ? "Adres e-mail jest niepoprawny" : false}
                        onBlur={(e) => setEmailError(!emailValidator(e.target.value))}
                        onChange={handleInputChange}
                        value={newInvitation?.recipient || ""}
                    />
                </Box>
                <Box marginBottom="16px">
                    <TextField
                    variant="outlined"
                    id="first_name"
                    label="Imię"
                    fullWidth
                    onChange={handleInputChange}
                    value={newInvitation?.first_name || ""}
                    />
                </Box>
                <Box marginBottom="16px" width="100%">
                    <TextField
                    variant="outlined"
                    id="last_name"
                    label="Nazwisko"
                    fullWidth
                    onChange={handleInputChange}
                    value={newInvitation?.last_name || ""}
                    />
                </Box>
                <Box>
                    <FormControl
                    variant="outlined"
                        sx={{
                            minWidth: 120,
                            width: "100%",
                            marginBottom: "16px",
                        }}
                    >
                    <InputLabel htmlFor="role-select">Rola</InputLabel>
                    <Select
                        native
                        value={newInvitation?.role}
                        onChange={handleSelectChange}
                        label="Rola"
                        inputProps={{
                        name: "role",
                        id: "role-select",
                        }}
                    >
                        <option aria-label="None" value="" />
                        <option value={UserRole.ADMIN}>Administrator</option>
                        <option value={UserRole.MENTOR}>Mentor</option>
                        <option value={UserRole.TEACHER}>Nauczyciel</option>
                        <option value={UserRole.STUDENT}>Uczeń</option>
                        <option value={UserRole.ALUMNI}>Absolwent</option>
                    </Select>
                    </FormControl>
                </Box>
                <Box marginBottom="16px" width="100%">
                    <Button
                        disabled={emailError}
                        onClick={handleSend}
                        type="primary"
                    >
                        Wyślij
                    </Button>
                </Box>
            </Box>

            <h1>Aktywne zaproszenia</h1>


            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{
                            backgroundColor: "#004e8d",
                            color: "#fff",
                            }}
                        >
                            {column.label}
                        </TableCell>
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {invitations.map((invitation) => (
                            <TableRow key={invitation.invitation_id}>
                                <TableCell>{invitation.recipient}</TableCell>
                                <TableCell>{invitation.first_name}</TableCell>
                                <TableCell>{invitation.last_name}</TableCell>
                                <TableCell>{getRoleNicename(invitation.role)}</TableCell>
                                <TableCell>{new Date(invitation.expires_at).toLocaleString()}</TableCell>
                                <TableCell align="right">
                                <ButtonMUI
                                    variant="outlined"
                                    size="small"
                                    sx={{marginRight: '8px'}}
                                    onClick={() => showDeleteModal(invitation.invitation_id)}
                                >
                                    Usuń
                                </ButtonMUI>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}